@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

* { margin: 0; padding: 0; }

:root {
    --url-color: #D64C3A;
    --text-color: #1a1a1a;
    --bg-color-main: #ffffff;

    --heading-text-size: 40px;
    --subheading-text-size: 28px;
    --section-text-size: 20px;
    --main-text-size: 16px;

    --main-padding: 30px;
}

body {
    background-color: var(--bg-color-main);
    font-family: 'Roboto', SansSerif, serif;
    font-weight: 400;
    color: var(--text-color);
    line-height: 1.4;
}

h1 {
    font-weight: 700;
    font-size: var(--heading-text-size);
}

h2 {
    font-weight: 700;
    font-size: var(--subheading-text-size);
}

h3 {
    font-weight: 700;
    font-size: var(--section-text-size);
}

p {
    font-size: var(--main-text-size);
}

a {
    color: var(--url-color);
}


/*
/ MAIN CONTAINER
 */

.wrapper {
    display: flex;
    justify-content: center;
}

.container {
    width: 100%;
    max-width: 1260px;
    padding: var(--main-padding);
}

.separator {
    height: 10px;
}



/*
/ HEADER
 */

.header-wrapper {
    width: 100%;
}

.header-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.header-logo {
    width: 150px;
    height: 85px;
}

.social-icons ul {
    display: flex;
}

.social-icons ul li {
    list-style-type: none;
    padding-right: 5px;
}

.social-icons li img {
    width: 36px;
    height: 36px;
}

.flex2col {
    display: flex;
}

.flex2col .left {
    width: 50%;
    padding-right: 5px;
}

.flex2col .right {
    padding-left: 5px;
    width: 50%;

}

/*
/ COUNTERS
 */

.counter {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.counter ul {
    list-style-type: none;
    align-items: center;
    display: flex;
    font-size: var(--subheading-text-size);
    font-weight: 700;
    color: #FFF;
}

.counter ul li {
    padding: 10px;
    background-color: var(--url-color);
    margin-right: 5px;
    margin-bottom: 5px;
}

.counter p {
    background-color: var(--url-color);
    color: #FFF;
    padding: 10px;
}

/*
/ ACTIVITIES
 */

.activity {
    width: 230px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.activity strong {
    font-size: 16px;
    font-weight: 700;
    color: var(--url-color);
    text-transform: capitalize;
    display: block;
}

.activity p {
    margin-top: 5px;
    color: #8A8A8A;
    font-size: 14px;
}

.activity span {
    font-size: 12px;
}

/*
/ TEXT BLOCK
 */

.text-section {
    background-color: var(--url-color);
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--main-padding);
}

/*
/ Buttons
 */

button {
    padding: 15px 25px;
    background-color: #039BE5;
    border-width: 0;
    border-radius: 25px;
    font-size: var(--main-text-size);
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
}

/*
/ Footer
 */

.footer-container {
    padding: var(--main-padding);
    background-color: #000000;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-container a{
    color: #fff;
}


/*
/ Media Queries
 */

@media (min-width: 0px) and (max-width:980px) {

    .flex2col {
        display: block;
    }

    .flex2col .left {
        width: 100%;
    }

    .flex2col .right {
        width: 100%
    }
}

@media (min-width: 450px) and (max-width:600px) {
    :root {
        --heading-text-size: 30px;
        --section-text-size: 18px;
        --subheading-text-size: 20px;
        --main-padding: 20px;
    }
}

@media (min-width: 0px) and (max-width:450px) {
    :root {
        --heading-text-size: 26px;
        --section-text-size: 16px;
        --subheading-text-size: 15px;
        --main-text-size: 14px;
        --main-padding: 20px;
    }

    .activity {
        width: 100%;
        margin-right: 15px;
        margin-bottom: 20px;
    }

    .counter {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

}

@media (min-width: 0px) and (max-width:330px) {
    :root {
        --heading-text-size: 24px;
        --subheading-text-size: 15px;
        --main-text-size: 13px;
        --main-padding: 15px;
    }


}
